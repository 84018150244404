import React from 'react'
import PricingBelow from '../PricingBelow/PricingBelow'
import './Pricing.css'

const Pricing = () => {
  return (
    <div className="pricing">
        <div className="heading">
            <h1>Everything you need for <span className='primary-color'>$99 a month</span></h1>
            <button>Get started today</button>
        </div>
        <p>Includes every feature we offer plus unlimited projects and unlimited users.</p>
        <div className="divider1"></div>
        <PricingBelow/>
    </div>
  )
}

export default Pricing