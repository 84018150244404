import React from 'react'
import Contact from '../Contact/Contact'
import Faq from '../Faq/Faq'
import Footer from '../Footer/Footer'
import Hero from '../Hero/Hero'
import Navbar from '../Navbar/Navbar'
import Pricing from '../Pricing/Pricing'
import Testimonials from '../Testimonials/Testimonials'
import './Home.css'

const Home = () => {
  return (
    <>
    <Navbar/>
    <Hero/>
    <Pricing/>
    <Testimonials/>
    <Faq/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default Home