import React from 'react';
import {Routes, Route} from 'react-router-dom'
import Home from './components/Home/Home'
import ContactUS from './routes/ContactUS';
import FAQ from './routes/FAQ';
import Pricing from './routes/Pricing';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/pricing' element={<Pricing/>}/>
        <Route path='/faq' element={<FAQ/>}/>
        <Route path='/contact' element={<ContactUS/>}/>
      </Routes>
    </>
  );
}

export default App;
