import React from 'react'
import { FaFacebook, FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import css from './Footer.module.css'

const Footer = () => {
  return (
    <div className={css.footer}>
        <div className={css.footer1}>
        <div className={css.row1}>
            <span>Solutions</span>
            <ul>
            <li className={css.r}><Link to='/'>Marketing</Link></li>
            <li className={css.r}><Link to='/'>Analytics</Link></li>
            <li className={css.r}><Link to='/'>Commerce</Link></li>
            <li className={css.r}><Link to='/'>Insights</Link></li>
            </ul>
        </div>
        <div className={css.row2}>
            <span >Support</span>
            <ul>
            <li className={css.r}><Link to='/'>Pricing</Link></li>
            <li className={css.r}><Link to='/'>Documentation</Link></li>
            <li className={css.r}><Link to='/'>Guides</Link></li>
            <li className={css.r}><Link to='/'>API Status</Link></li>
            </ul>
        </div>
        <div className={css.row3}>
        <span>Company</span>
        <ul>
            <li className={css.r}><Link to='/'>About</Link></li>
            <li className={css.r}><Link to='/'>Blog</Link></li>
            <li className={css.r}><Link to='/'>Jobs</Link></li>
            <li className={css.r}><Link to='/'>Press</Link></li>
            <li className={css.r}><Link to='/'>Partners</Link></li>
            </ul>
        </div>
        <div className={css.row4}>
        <span>Legal</span>
        <ul>
            <li className={css.r}><Link to='/'>Claim</Link></li>
            <li className={css.r}><Link to='/'>Privacy</Link></li>
            <li className={css.r}><Link to='/'>Terms</Link></li>
            </ul>
        </div>
        <div className={css.row5}>
            <span>Subscribe to our newsletter</span>
            <p>The latest news, articles and resources, sent to your inbox weekly</p>
            <div className={css.email}>
            <input type="email" placeholder='Enter your email'/>
            <button>Subscribe</button>
            </div>
        </div>

        
        </div>
        <div className={css.divider}></div>
        <div className={css.bottom}>
            <span>&copy; Workflow, Inc. All rights reserved.</span>
            <div className={css.icons}>
                <FaFacebook size={20} style={{color: '#d3d3d3', marginRight: '10px'}}/>
                <FaInstagram size={20} style={{color: '#d3d3d3', marginRight: '10px'}}/>
                <FaTwitter size={20} style={{color: '#d3d3d3', marginRight: '10px'}}/>
                <FaGithub size={20} style={{color: '#d3d3d3', marginRight: '10px'}}/>
            </div>
        </div>
    </div>
  )
}

export default Footer