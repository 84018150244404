import React from 'react'
import './Hero.css'
import {FaDatabase, FaAsterisk, FaAccusoft, FaFacebook, FaTwitter, FaGithub} from 'react-icons/fa'

const Hero = () => {
  return (
    <div className="hero">
        <div className="left">
            <span>Data to enrich your</span>
            <span>online business</span>
            <p>Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s,
                when an unknown printer took<br/> a galley of type.</p>
            <span>USED BY</span>
            <div className="icons">
                <i><FaDatabase/> Staxx</i>
                <i><FaAsterisk/> Star AI</i>
                <i><FaAccusoft/> Accusoft</i>
            </div>
        </div>

        {/* right side */}
        <div className="right">
            <div className="form-layout">
                <div className="form-container">
                    <p className="signin-text">Sign in with</p>
                    <div className="social-login">
                        <i><FaFacebook size={20}/></i>
                        <i><FaGithub size={20}/></i>
                        <i><FaTwitter size={20}/></i>
                    </div>
                    <div className="divider">
                        <p><span>Or</span></p>
                    </div>
                    <form action="">
                        <input type="text" placeholder='Name'/>
                        <input type="email" placeholder='Email'/>
                        <input type="password" placeholder='Password'/>
                        <button>Create your account</button>
                    </form>
                </div>
                <div className="form-footer">
                    <p>By signing up, you agree to our
                    <span className='primary-color'> Terms, Data Policy</span> and
                    <span className='primary-color'> Cookies Policy</span>.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero