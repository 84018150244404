import React from 'react'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import css from './Contact.module.css'

const Contact = () => {
  return (
    <div className={css.contact}>
        <div className={css.left}>
            <div className={css.heading}>
                <h1>Get in touch</h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam,
                iusto natus fugit saepe minima rem nemo laborum ea dolorum
                est.</p>
            </div>
            <span>742 Evergreen terrace</span>
            <span>Springfield, OR 12345</span>
            <i><FaPhone/> <span>+966 59 675 5881</span></i>
            <i><FaEnvelope/> support@example.com</i>
            <p>Looking for careers? <span className='primary-color'>View all job openings</span></p>
        </div>

        <div className={css.right}>
            <input type="text" placeholder='Full name'/>
            <input type="email" placeholder='Email'/>
            <input type="phone" placeholder='Phone'/>
            <textarea name="Message" placeholder='Message' cols="30" rows="10"></textarea>
            <div className={css.checkbox}>
                <input type="checkbox" />
                <p>By checking this box, you agree to the <span>Privacy Policy</span> and
                <span> Cookie Policy</span>.</p>
            </div>
            <button>Submit</button>
        </div>
    </div>
  )
}

export default Contact