import React from 'react'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

const ContactUS = () => {
  return (
    <>
    <Navbar/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default ContactUS