import React from 'react'
import './PricingBelow.css'

const PricingBelow = () => {
  return (
    <div className="pricingbelow">
        <div className="first">
            <span>everything you need</span>
            <h1>All-in-one platform</h1>
            <p>lorem ipsum dolor sit aemet consectatur
                adispiscing out. Quo soruta, temporitius ea esf
                ipsum veno recusandas banditiis ut doloritius
                excepture.
            </p>
        </div>
        <div className="second">
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
        </div>
        <div className="second">
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
            <div className="divider2"></div>
            <p>lorem ipsum dolor sit amet consecteur</p>
        </div>
    </div>
  )
}

export default PricingBelow