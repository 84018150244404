import React from 'react'
import './Faq.css'

const Faq = () => {
  return (
    <div className="section-faq">
        <div className="container">
            <div className="col-1">
                <h3>Frequently asked questions</h3>
                <p>Can't find the answer you're looking for?</p>
                <p>React out to our <span className='primary-color'>customer support </span>team.</p>
            </div>
            <div className='col-2'>
                    <div className='faq'>
                        <h5>How do you make holy water?</h5>
                        <p>You biol the hell out of it. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div className='faq'>
                        <h5>What's the best thing about switzerland?</h5>
                        <p>You biol the hell out of it. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div className='faq'>
                        <h5>What do you call someone with no body and no nose?</h5>
                        <p>You biol the hell out of it. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div className='faq'>
                        <h5>Why do you never see elephants hiding in trees?</h5>
                        <p>You biol the hell out of it. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Faq